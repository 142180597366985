import React, { FC, useContext, useEffect } from 'react';
import { AppContext } from '../../store/AppContextProvider';
import { Link } from 'gatsby';
import { ButtonVariantEnum } from '../../utils/enums/button';

import Button from '../button/Button';
import BackArrow from '../../assets/icons/BackArrow.inline.svg';

import './Faqs.scss';

const Faqs: FC<{}> = () => {
    const { hashtags, pageContent } = useContext(AppContext);

    useEffect(() => {
        document.querySelector('html')?.classList.remove('disable-scroll');
    }, []);

    return (
        <section className="faqs">
            <div className="faqs__wrapper">
                <Link className="faqs__link" to="/map">
                    <Button
                        label="BACK"
                        leftIcon={<BackArrow />}
                        type={ButtonVariantEnum.SECONDARY}
                        onClick={() => {}}
                    />
                </Link>
                <div className="faqs__content-wrapper">
                    <div className="faqs__hashtags-wrapper">
                        {hashtags.map((hashtag) => {
                            return <p className="faqs__hashtag">{`#${hashtag.name}`}</p>;
                        })}
                    </div>
                    <div className="faqs__text-wrapper">
                        <h1 className="faqs__title">{pageContent?.faqs.heading}</h1>
                        <hr className="faqs__line" />
                        {pageContent?.faqs.sections.map((section) => {
                            return (
                                <>
                                    <h2 className="faqs__subtitle">{section.title}</h2>
                                    <p className="faqs__text">{section.description}</p>
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faqs;
