import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../layout/layout/Layout';
import Faqs from '../components/faqs/Faqs';

const FaqsPage: FC = () => {
    return (
        <>
            <Helmet title="Get Real - Got Milk. | How We Score" />
            <Layout pageClassName="faqs-page">
                <Faqs />
            </Layout>
        </>
    );
}

export default FaqsPage;